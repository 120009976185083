.marquee {
    overflow: hidden;
    white-space: nowrap;
    width: 80%;
    background: white-space;
    
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 10%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }
  
  .marquee-inner {
    display: inline-block;
    animation: marquee 15s linear infinite;
  }
  
  .marquee img {
    height: 60px;
    margin: 0 30px;
    width: 120px;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }