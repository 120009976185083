.gallery{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	height: 100%;
}
figure.grid {
	font-family: 'Playfair',sans-serif;
	color: #fff;
	position: relative;
	overflow: hidden;
	margin: 10px;
	min-width: 220px;
	max-width: 310px;
	max-height: 220px;
	width: 100%;
	background: #000000;
	text-align: left;
  }

  .textHide{
	font-size: 16px;
	position: absolute;
	bottom: 10px;
	left: 110px;
	background-color: rgba(0, 0, 0, 0.568);
	padding: 4px 8px;
	color: white;
	margin: 0;
  }

  figure.grid:hover .textHide{
	display: none;
  }

@media (max-width: 767px) {
	.gallery {
		display: flex;
		flex-direction: column;
		width: 100%;
	  }
}  

  figure.grid * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
  }
  figure.grid img {
	max-width: 100%;
	opacity: 1;
	height: 250px;
	width: 100%;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
  }
  figure.grid figcaption {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 30px 3em;
	width: 100%;
	height: 100%;
  }
  figure.grid figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 100%;
	border-left: 4px solid rgba(255, 255, 255, 0.8);
	content: '';
	opacity: 0;
	background-color: rgba(255, 255, 255, 0.5);
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
  }
  figure.grid h2,
  figure.grid p {
	margin: 0 0 5px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
  }
  figure.grid h2 {
	word-spacing: -0.15em;
	font-weight: 300;
	font-size: 25px;
	text-transform: uppercase;
	-webkit-transform: translate3d(30%, 0%, 0);
	transform: translate3d(30%, 0%, 0);
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
  }
  figure.grid h2 span {
	font-weight: 800;
  }
  figure.grid p {
	font-weight: 200;
	-webkit-transform: translate3d(0%, 30%, 0);
	transform: translate3d(0%, 30%, 0);
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
  }
  figure.grid a {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	color: #ffffff;
  }
  figure.grid:hover img {
	opacity: 0.3;
  }
  figure.grid:hover figcaption h2 {
	opacity: 1;
	-webkit-transform: translate3d(0%, 0%, 0);
	transform: translate3d(0%, 0%, 0);
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
  }
  figure.grid:hover figcaption p {
	opacity: 0.9;
	-webkit-transform: translate3d(0%, 0%, 0);
	transform: translate3d(0%, 0%, 0);
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
  }
  figure.grid:hover figcaption::before {
	background: rgba(255, 255, 255, 0);
	left: 30px;
	opacity: 1;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
  }