.heroBanner {
    background: url("/public/hero.jpg") no-repeat no-repeat;
    height: 800px;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    position: relative;
  }
  
  @media (max-width: 767px) {
    .heroBanner {
      height: 890px;
      background-size: cover;
      background-attachment: fixed;
      position: relative;
    }
    .heroWave {
      width: 100%;
      position: absolute;
      left: 0;
      margin-bottom: -10px;
    }
  }
  
  .heroWave {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  
  img {
    height: 50%;
    width: 50%;
  }
  
  .heroText {
    width: 600px;
    letter-spacing: 3px;
    padding: 50px;
    margin-left: 50px;
    margin-top: 20%;
  }
  
  .heroText h2 {
    font-family: "Abril Fatface", serif;
  }
  
  .heroText h3 {
    font-family: "Playfair Display", serif;
  }
  
  @media (max-width: 767px) {
    .heroText {
      width: 100%;
      margin-left: 0;
      padding: 5px;
      text-align: center;
      margin-top: 450px;
    }
  }
  
/*--Hero button-*/



.heroBtn {
	font-family: helvetica, san-serif;
	font-size: 18px;
	cursor: pointer;
	padding: 15px 50px;
	display: inline-block;
	margin: 15px 30px;
	letter-spacing: 1px;
	font-weight: 600;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.heroBtn:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-1 {
	border-radius: 50px;
  border: none;
  background: #015cae;
	color: #fff;
	overflow: hidden;
}

.btn-1:active {
	color: orange;
}

.btn-1:hover {
	background: orange;
  color: #fff;
}

.btn-1 svg {
	position: absolute;
  left: 130%;
	top: 0;
	height: 100%;
	font-size: 125%;
	line-height: 3.5;
	color: #333;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-1:active:before {
	color: #545454;
}

.btn-1:hover svg {
	left: 85%;
}