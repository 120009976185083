
.serviceWrapper {
  width: 100%;
  padding: 0 8%;
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .serviceWrapper{
    width: 100%;
    margin-bottom: 80px;
    justify-content: center;
  }


}
.service4Text{
  text-align: center;
  padding-top: 6%;
  margin-bottom: 60px;
  font-size: 1.8rem;
  font-weight: 600;
  position: relative;
}
.serviceWrapper p {
 padding: 10px;
}
.service4Text::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 5px;
  background-color:  orange;
  border-radius: 20px;
}

.service {
  padding: 25px 15px;
  background: transparent;
  font-size: 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
@media (max-width:767) {
  .service{
    background: transparent;
    font-size: 25px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
  }
  .service h2 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .service:hover {
    color: #fff;
    background-color:  orange;
    transform: scale(1.05);
  }
  .service i {
    color:  orange;
    margin-bottom: 20px;
    font-size: 50px;
    transition: 0.5s;
  }
    }


.service:hover {
  color: #fff;
  background-color:  orange;
  transform: scale(1.05);
}
.service i {
  color:  orange;
  margin-bottom: 20px;
  font-size: 40px;
  transition: 0.5s;
}
.service:hover i {
  color: #fff;
}
.service h2 {
  font-weight: 600;
  margin-bottom: 20px;
}