header .navbar{
    position: absolute;
    width: 100%;
    border-color: transparent;
    z-index: 1000;
}

header .navbar.sticky{
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--white);
    -webkit-transition: all 15ms ease-in-out;
    -moz-transition: all 15ms ease-in-out;
    -o-transition: all 15ms ease-in-out;
    -ms-transition: all 15ms ease-in-out;
    transition: all 15ms ease-in-out;
    animation-name: stickyTransition;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
    -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
    box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

@keyframes stickyTransition {
    0%{
        opacity: 0;
        -webkit-transform: translate3d(0,-100,0);
        transform: translate3d(0,-100,0);
    }
    100%{
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

header .logo img{
    width: 150px;
    min-width: inherit;
    min-height: 60px;
}

header .nav-link{
    display: flex;
    justify-content: center;
    margin-right: 20px;
    color: var(--white);
    padding: 0.1rem !important;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: var(--oswald-font);
    text-transform: uppercase;
    transition: all 15ms ease-in-out;
    -webkit-transition: all 15ms ease-in-out;
    -moz-transition: all 15ms ease-in-out;
    -o-transition: all 15ms ease-in-out;
    -ms-transition: all 15ms ease-in-out;
}

header .sticky .nav-link{
    color: var(--light-black);
}

header .nav-link:hover{
    color: var(--yellow);
}

header .card{
    position: relative;
    top: -0.4375rem;
}

@media (max-width: 992px) {
    header .navbar{
        background-color: var(--white);
    }
    header .nav-link{
        justify-content: center;
    }

    header .navbar-toggler{
        border: none;
    }
    header .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
    }
    header .nav-link{
        color: var(--light-black);
        padding: 1rem !important;
    }

}