.section5{
  background: url("/public/images/contact.jpg");
  height: 100vh;
}

.contactWrapper{
    position: relative;
    top: 40px;
    left: 130px;
    height: 90%;
    width: 80%;
    transform: translate(-0%, -0%);
    background: rgba(0,0,0,0.8);
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
  
  .contactWrapper .title h1{
    color: rgb(231, 194, 126);
    text-align: center;
    margin-bottom: 25px;
  }
  
  .contactForm{
    display: flex;
  }
  
  .inputFields{
    display: flex;
    flex-direction: column;
    margin-right: 4%;
  }
  
  .inputFields,
  .msg{
    width: 48%;
  }
  
  .inputFields .input,
  .msg textarea{
    margin: 10px 0;
    background: transparent;
    border: 0px;
    border-bottom: 2px solid rgb(231, 194, 126);
    padding: 10px;
    color: rgb(231, 194, 126);
    width: 100%;
  }
  
  .msg textarea{
    height: 212px;
  }
  
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgb(231, 194, 126);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgb(231, 194, 126);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgb(231, 194, 126);
  }
  
  .btn {
        width: 100%;
        cursor: pointer;
        text-transform: uppercase;
  }
  @media (max-width: 767px) {
   .section5{
    height: 100vh;
   }
  .contactForm{
    display: flex;
    flex-direction: column;
  }
  .contactWrapper{
  margin-left: -90px;
  }
  
  .inputFields .input,
  .msg textarea{
    width: 250px;
  }
  }
 