
.section3Wrapper {
          
  min-height: 100vh;
}

.blog img{
width: 100%;
height: 250px;
}
.blog-head {
margin-bottom: 70px;
}

.blog-head h6{
color: #f05907;
position: relative;
display: inline-block;
text-transform: capitalize;
}

.blog-head h6:after, .blog-head h6:before{
position: absolute;
content: "";
width: 50px;
height: 3px;
background: #f05907;
top: 50%;
}

.blog-head h6:after{
right: 120%;
}

.blog-head h6:before{
left: 120%;
}

.textBottom {
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  left: 130px;
  background-color: rgba(0, 0, 0, 0.568);
  padding: 4px 8px;
  color: white;
  margin: 0;
}

.item:hover .textBottom{
 display: none;
}

.overlay {
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
background-color: rgba(240, 89, 7, 0.82);
overflow: hidden;
width: 100%;
height: 100%;
transition: .5s ease;
}

.item {
position: relative;
overflow: hidden;
margin: 20px 0;
}

.item:hover .overlay {
bottom: 0;
height: 100%;
width: 100%;
}

.bottom-overlay {
top: 100%;
}

.item:hover .bottom-overlay {
top: 0;
}

.p-text {
color: white;
font-size: 15px;
width: 300px;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
text-align: center;
}

@media (max-width: 767px) {

.blog-head h6{
color: #f05907;
position: relative;
display: inline-block;
text-transform: capitalize;
}

.blog-head h6:after, .blog-head h6:before{
position: absolute;
content: "";
width: 10px;
height: 2px;
background: #f05907;
top: 40%;
}


@media (max-width: 767px) {
.p-text{
font-size: 20px;
padding: 0px;
width: 350px;
}
}
}