footer{
    background-color: #010414;
}

.footerItem{
    display: flex;
    flex-direction: column;
}
.footerItem > a{
    text-decoration: none;
    line-height: 2;
}
.footerContent{
    text-align: start;
}

.footerLogo{
    height: 120px;
    width: 300px;
}