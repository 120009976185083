.banner {
    background: url("/public/header.webp");
    height: 400px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
  }
  
  .banner .waves {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -1px;
  }
  
  .headerText {
    text-align: center;
    margin-top: 8%;
    color: white;
    width: 700px;
    font-family: "Lucida Sans", Verdana, sans-serif;
  }
  
  @media (max-width:767px) {
    .banner {
      height: 250px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      position: relative;
    }
    
    .banner .waves {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
    }
    
    .headerText {
      text-align: center;
      margin-top:35%;
      width: 400px;
    }
  }
  
  /*    Case Study section    */
  
  .card {
    margin-top: 5px;
    width: 350px;
  }
  .card > img {
    height: 250px;
  }
  
  .caseContainer {
    padding: 50px;
  }
  
  @media (max-width: 767px) {
    .caseContainer {
      padding: 10px;
      margin-left: 0;
      text-align: center;
    }
  
    .card {
      margin-top: 5px;
      width: 350px;
      margin-left: 20px;
    }
    
  }
  
  /* About Css */
  
  .aboutImg {
    width: 600px;
    height: 450px;
  }
  
  .heading {
    font-family: "Alkatra", system-ui;
    font-size: 40px;
    font-weight: 500;
    color: #483168;
    margin-bottom: 20px;
    text-align: start;
  }
  
  .textContainer h4 {
    font-family: "Playfair", serif;
    font-size: 25px;
    line-height: 1;
    font-weight: 500;
    text-align: start;
    color: orange;
  }
  
  .textContainer span {
    font-family: "Playfair", serif;
    font-weight: 600;
    font-size: 40px;
    color: brown;
  }
  .aboutWrapper {
    margin-top: 40%;
  }
  
  .aboutWrapper h1 {
    text-align: center;
    text-transform: capitalize;
    font-family: "Abril", serif;
    font-weight: 500;
  }
  .aboutContent {
  text-align: center;
  }
  
  .aboutIcon {
    font-size: 50px;
  }
  
  .aboutContent p {
    font-weight: 400;
    width: 250px;
    margin-left: 15%;
  }
  
  @media (max-width:767px) {
    .aboutImg {
      width: 100%;
      height: 400px;
    }
    .aboutIcon {
      font-size: 50px;
      margin: 0;
      padding: 0;
    }
    .aboutContent {
      margin-left: 10px;
      padding: 0;
    }
    .aboutContent p{
      text-align: center !important;
      margin-left: 15%;
    }
    .textContainer h4{
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .aboutWrapper{
      text-align: center;
    }
    .heading {
      text-align: center;
    }
    
  }
  
  /*   Cloud Services    */
  
  @media (max-width:767px) {
    .cloudService1{
      padding: 15px;
    }
    .cloudService4{
      padding: 15px;
    }
  }
  
  .serviceText{
    font-size: 25px;
    text-align: center;
  }
  
  .cloudWrapper{
    margin-top: 40%;
  }
  
  .cardHeading{
    text-transform: capitalize;
    font-size: 18px;
  }
  
  .cardText {
    color: grey;
    font-size: 14px;
  }
  
  .circle{
    background: #1e3168;
    border-radius: 50%;
    color: #fff;
    height: 4em;
    position: relative;
    width: 4em !important;
  }
  
  .circleIcon{
    fill: currentColor;
    font-size: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  
  .sec-icon {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
  }
  
  .sec-icon::before {
    content: "";
    position: absolute;
    height: 1px;
    left: -70px;
    margin-top: -5.5px;
    top: 60%;
    background: #333333;
    width: 50px;
  }
  
  .sec-icon::after {
    content: "";
    position: absolute;
    height: 1px;
    right: -70px;
    margin-top: -5.5px;
    top: 60%;
    background: #333;
    width: 50px;
  }
  
  .cloud-service-sec {
    background-color: #f5f5f5;
  }
  
  .cloud-service-sec span {
    color: orange;
  }
  
  .cloud-service-sec .col {
    padding: 0 1em 1em 1em;
    text-align: center;
  }
  
  .cloud-service-sec .service-card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
  }
  
  .cloud-service-sec .service-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#aeafaf, orange);
    position: absolute;
    left: 0%;
    top: -98%;
    z-index: -2;
    transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
  }
  
  .cloud-service-sec h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
    z-index: 3;
  }
  
  .cloud-service-sec p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    z-index: 3;
  }
  
  .cloud-service-sec .icon-wrapper {
    background-color: #2c7bfe;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    z-index: 3;
  }
  
  .cloud-service-sec .service-card:hover:after {
    top: 0%;
  }
  
  .service-card .icon-wrapper {
    background-color: #ffffff;
    color: orange;
  }
  
  .cloud-service-sec .service-card:hover .icon-wrapper {
    color: #353c99;
  }
  
  .cloud-service-sec .service-card:hover h3 {
    color: #ffffff;
  }
  
  .cloud-service-sec .service-card:hover p {
    color: #f0f0f0;
  }
  
  
  
  .h3Text{
    font-family: "Alkatra", system-ui;
    margin-top: 30px;
  text-align: center;
  font-size: 30px;
  }
  
  .pText{
    color: rgb(160, 158, 158);
    text-align: center;
  }
  
  .cloudImg{
    height: 200px;
    width: 250px;
  }
  
  .ser3{
    position: relative;
    text-align: center;
  }
  
  .overlay-text{
    position: absolute;
    color: white;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .overlay-text h3{
    font-size: 18px;
    text-transform: capitalize;
  }
  
  .headText{
    text-align: center;
    margin-top:40px;
    font-size: 35px;
    font-family: "Alkatra", system-ui;
  }
  

  /*    Backup Service   */
  
  .backupContainer{
    margin-left: 5%;
  }
  
  .imageContainer{
    background-color: #f7f5f5;
    padding: 1px;
  }
  
  .backupGallery{
    height: 50vh;
  }
  
  .backupGrid{
    padding: 0 6px;
    float: left;
    width: 25%;
  }
  
  .backupImg img{
    width: 100%;
    height: 200px;
  }
  
  
  
  @media (max-width:767px) {
    .backupGrid{
      width:50%;
      margin: 6px 0;
    }
  }
  
  /*  Data center   */
  
  .dataWrapper{
    margin-left: 20px;
  }
  
  .dataContainer{
    background-color: #f3f2f2;
    padding: 1px;
    margin-left: 22px;
  }
  
  @media (max-width:767px) {
  
    .dataContainer{
      padding: 10px;
      margin: 0;
    }
    .dataGallery{
      margin-left: 15% !important;
    }
  }
  
  /* FMS Service */
  
  @media (max-width:767px) {
    .fmsIconsData{
      margin-left: 5%;
    }
    .fmsContainer{
      margin-left: 18%;
    }
  }
  

  /*  Consulting service   */

  
.features{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.feature{
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}

.featureIcon{
font-size: 35px;
color: #2c7bfe;
}

.featureTitle{
  font-size: 20px;
  font-weight: 600;
  margin: 10px;
}

.featureDesc{
color: gray;
width: 60%;
height: 100px;
}


  .consultancyContainer{
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .a-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  .a-right{
    flex: 1;
  }

  .a-card.imgBack{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
  }

  .a-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }

  .a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .a-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
  
  .a-title {
    font-weight: 400;
  }
  
  .a-sub {
    margin: 20px 0px;
  }
  
  .a-desc{
      font-weight: 300;
  }
  
  .a-award{
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .a-award-texts{
      width: 70%;
  }
  
  .a-award-title{
      font-weight: bold;
      color: #555;
      margin-bottom: 10px;
  }
  
  @media screen and (max-width:480px) {
      .consultancyContainer{
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
      }
  
      .a-left {
        width: 100%;
      }
  
      .a-card {
        height: 30vh;
      }
  
      .a-card.bg, .a-award {
        display: none;
      }
  
      .a-right{
        padding: 20px;
      }
  }